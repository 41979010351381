import React from "react";

const IBANMasked = ({ iban }) => {
  // Function to mask the IBAN number
  const maskIBAN = (iban) => {
    if (!iban || typeof iban !== "string") return "N/A";

    // Keep the first 4 characters and mask the rest with asterisks
    const visiblePart = iban.substring(0, 4);
    const maskedPart = iban.substring(4).replace(/./g, "*");

    return `${visiblePart}${maskedPart}`;
  };

  return <span className="f-12">{maskIBAN(iban)}</span>;
};

export default IBANMasked;
