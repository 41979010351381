// import React, { useState } from 'react';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
const { Header, Sider, Content } = Layout;

const About = () => {
  // const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  // const toggleSideNav = () => {
  //   setIsSideNavOpen(!isSideNavOpen);
  // };

  // const sideNavStyle = {
  //   width: isSideNavOpen ? '20px' : '0',
  //   border: '1px solid black',
  //   transition: 'width 0.3s',
  // };

  // const mainContentStyle = {
  //   marginLeft: isSideNavOpen ? '20px' : '0',
  //   border: '1px solid red',
  //   transition: 'margin 0.3s',
  // };
  // return (
  //   <>
  //   <div className="App" style={{display: 'flex'}}>
  //     <div className="sidenav" style={sideNavStyle}>
  //       <button onClick={toggleSideNav}>&times;</button>
  //       <a href="#">Link 1</a>
  //       <a href="#">Link 2</a>
  //       <a href="#">Link 3</a>
  //     </div>

  //     <div className="main-content" style={mainContentStyle}>
  //       <button onClick={toggleSideNav}>Open Sidenav</button>
  //     </div>
  //   </div>
  //   </>
  // )



  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div style={{display: 'flex', padding: '10px'}}>
          <div style={{flex: '2', textAlign: 'center'}}>
          <h6 style={{color: 'white'}}>Trufit</h6>
          </div>
          <div style={{flex: '1'}}>
          {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          /> */}
          <button type="button" onClick={() => setCollapsed(!collapsed)}>Click</button>
          </div>
        </div>
        

        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              icon: <UserOutlined />,
              label: 'nav 1',
            },
            {
              key: '2',
              icon: <VideoCameraOutlined />,
              label: 'nav 2',
            },
            {
              key: '3',
              icon: <UploadOutlined />,
              label: 'nav 3',
            },
          ]}
        />
      </Sider>
      <Layout>
        {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >

        </Header> */}
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          Content
        </Content>
      </Layout>
    </Layout>
  );
}

export default About