import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Divider, Row } from 'antd';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

const TransactionDetailDialog = (props) => {

  const [date, setDate] = useState('');

  useEffect(() => {
    const date = moment(props?.data?.created_at); // Replace this with your date and timezone
    setDate(date.format('D MMMM YYYY HH:mm [GMT] ZZ'));
  
  }, [])

  const twoDecimal = (num)=> {
    return  num ? Number(num).toFixed(2) : '0.00';
  }

  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Transaction Details
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row justify="space-between">
        <Col span={19}>
            <span className='bold'>{date}</span> <br></br>
            <span className='light-grey'>Transaction ID: {props?.data?.id}</span>
        </Col>
        <Col span={5}>
            <Row justify="end" align={'middle'}>
            <Col>
                <span className='bold'>${twoDecimal(props?.data?.commission_amount)}</span> <br></br>
                <span style={{ color: props?.data?.status == 'Pending' ? '#F89728' : props?.data?.status == 'Failed' ? 'red' : '#00DA57' }}>{props?.data?.status}</span></Col>
            </Row>
        </Col>
     </Row>
     <Divider></Divider>


     {props?.data?.affiliate_user_bank_information?.type == 'bank_account' && 
                      
        <>
             <h6 className='bold'>Account Details</h6>
             <Row justify="space-between">
                  <Col span={10}>
                      <span className='light-grey'>Bank</span> <br></br>
                      <span className='light-grey'>Account Holder</span> <br></br>
                      <span className='light-grey'>IBAN</span>
                  </Col>
                  <Col span={13}>
                      <Row justify="end" align={'middle'}>
                          <Col style={{textAlign: 'end'}}>
                          <span className='bold'>{props?.data?.affiliate_user_bank_information?.bank_name}</span> <br></br>
                          <span className='bold'>{props?.data?.affiliate_user_bank_information?.account_holder}</span> <br></br>
                          <span className='bold'>{props?.data?.affiliate_user_bank_information?.iban}</span>
                          </Col>
                      </Row>
                  </Col>
              </Row>
        </>
          
      }



     {props?.data?.affiliate_user_bank_information?.type == 'paypal' && 
                      
        <>
          <h6 className='bold'>Paypal Details</h6>
          <Row justify="space-between">
              <Col span={10}>
                  <span className='light-grey'>{props?.data?.affiliate_user_bank_information?.email ? 'Email' : 'Phone' }</span> <br></br>
              </Col>
              <Col span={13}>
                  <Row justify="end" align={'middle'}>
                      <Col style={{textAlign: 'end'}}>
                      <span className='bold'>{props?.data?.affiliate_user_bank_information?.email ? props?.data?.affiliate_user_bank_information?.email : props?.data?.affiliate_user_bank_information?.phone}</span>
                      </Col>
                  </Row>
              </Col>
          </Row>
        </>
          
      }

      <div className='mt-30 t-center'>
         <Button variant="primary" onClick={props.closeModal} style={{width: '90%', borderRadius: '21px', padding: '10px 10px'}}> Close </Button>{' '}
      </div>


    </Modal.Body>
    {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer> */}
  </Modal>
  
  )
}

export default TransactionDetailDialog