import React from 'react'
import LoadingOverlay from './loader'
import Logo from './../assets/images/img_logo_full.png';

const AuthFrame = ({loading, myComp}) => {
  return (
    <>
      {loading && <LoadingOverlay />}


<img style={{ display: "block", marginLeft: "auto", marginRight: "auto" , marginTop:"45px"}} height={100} src={Logo} alt="" />
<div style={{ textAlign: 'center', fontSize: '40px', padding: '45px' }}>
    <strong>Affiliate</strong> Dashboard
</div>

<div style={{display: "block", marginLeft: "auto", marginRight: "auto", width: '450px', padding: '45px', boxShadow: '0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1)', background: 'white' }}>

   {myComp()}
</div>


    </>
  )
}

export default AuthFrame