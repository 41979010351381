import React, { useRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Divider, Row, Form, message } from "antd";
import Button from "react-bootstrap/Button";
import bankIcon from "./../assets/images/bank.png";
import paypalIcon from "./../assets/images/paypal.png";
import tickIcon from "./../assets/images/tick.png";
import nonSelectedTick from "./../assets/images/non-selected-tick.png";
import selectedTick from "./../assets/images/selected-tick.png";
import axios from "./../helper/api/api_instance";
import { Radio } from "antd";
import moment from "moment";
import LoadingOverlay from "./loader";
import OTPInput from "otp-input-react";
import IBANMasked from "./IBANMasked";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const WithdrawMoneyDialog = (props) => {
  const [currentScreen, setCurrentScreen] = useState("paymentMethod");
  const [selectedBankAcc, setSelectedBankAcc] = useState({});
  const [amount, setAmount] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("bank");
  const [transactionData, setTransactionData] = useState({});
  let user = JSON.parse(localStorage.getItem("AuthUser"));
  const restrictAlphaphets = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    event.target.value = value;
  };

  const PaymentMethodTemplate = () => {
    let selectedBank = selectedMethod == "bank" ? "selectedMethod" : "";
    let selectedPaypal = selectedMethod == "paypal" ? "selectedMethod" : "";

    const addPayment = () => {
      if (selectedMethod == "bank") {
        axios
          .get(`affiliate/accounts-list`)
          .then((res) => {
            // setLoading(false);
            let response = res?.data;
            if (response.statusCode === 200) {
              let tempList = response.data.accountList;
              let bankAccList = tempList.filter(
                (x) => x.type == "bank_account"
              );
              let paypalAccList = tempList.filter((x) => x.type == "paypal");
              let list = selectedMethod == "bank" ? bankAccList : paypalAccList;
              if (list.length != 0) {
                setCurrentScreen("selectAccount");
              } else {
                setCurrentScreen(
                  selectedMethod == "bank" ? "bankForm" : "paypalForm"
                );
              }
            }
          })
          .catch((error) => {
            // toastr.error(error.message);
            // setLoading(false);
          });
      } else {
        setCurrentScreen("selectAccount");
      }
    };

    return (
      <div style={{ padding: "10px 25px" }}>
        <h6>
          <strong>Add Payment Method</strong>
        </h6>
        <Row justify="space-between" className="mt-20">
          <Col span={11}>
            <div
              onClick={() => setSelectedMethod("bank")}
              className={`paymentMethod ${selectedBank}`}
            >
              <img src={bankIcon} width={"35px"} alt="" />
              <div className="mt-10">
                <strong>Bank Account</strong>
              </div>
              <div style={{ fontSize: "12px" }}>
                Transfer Money directly into <br></br> bank account
              </div>
            </div>
          </Col>
          <Col span={11}>
            <div
              onClick={() => setSelectedMethod("paypal")}
              className={`paymentMethod ${selectedPaypal}`}
            >
              <img src={paypalIcon} width={"35px"} alt="" />
              <div className="mt-10">
                <strong>Paypal</strong>
              </div>
              <div style={{ fontSize: "12px" }}>
                Transfer your earnings to your <br></br> paypal account
              </div>
            </div>
          </Col>
        </Row>

        <div className="mt-30 t-center mb-20">
          <Button
            onClick={addPayment}
            variant="primary"
            style={{ width: "100%", borderRadius: "21px" }}
          >
            {" "}
            Continue{" "}
          </Button>{" "}
        </div>
      </div>
    );
  };

  const SelectBankAccountTemplate = () => {
    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [isLoading, setLoading] = useState();

    useEffect(() => {
      getAccountList();
    }, []);

    const getAccountList = () => {
      setLoading(true);

      axios
        .get(`affiliate/accounts-list`)
        .then((res) => {
          let response = res.data;
          setLoading(false);
          if (response.statusCode === 200) {
            let tempList = response?.data?.accountList;
            let list = tempList.filter(
              (x) =>
                x.type == (selectedMethod == "bank" ? "bank_account" : "paypal")
            );
            if (list.length != 0) {
              setAccountList(list);
            } else {
              setCurrentScreen(
                selectedMethod == "bank" ? "bankForm" : "paypalForm"
              );
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const selectAccount = () => {
      setSelectedBankAcc(selectedAccount);
      setCurrentScreen("sendAmount");
    };

    return (
      <>
        {isLoading && <LoadingOverlay />}

        <Row justify="space-between" className="mt-20 mb-20">
          <Col className="f-16">
            <h6>
              <strong>To</strong>
            </h6>
          </Col>
          <Col className="f-16">
            <Button
              onClick={() =>
                setCurrentScreen(
                  selectedMethod == "bank" ? "bankForm" : "paypalForm"
                )
              }
              variant="primary"
              type="submit"
              style={{
                background: "black",
                borderColor: "black",
                borderRadius: "21px",
              }}
              className="f-14"
            >
              {" "}
              Add New Account{" "}
            </Button>{" "}
          </Col>
        </Row>

        <div style={{ height: "auto", maxHeight: "275px", overflowY: "auto" }}>
          {accountList.map((element) => {
            return (
              <div
                onClick={() => setSelectedAccount(element)}
                className="mt-30"
                key={element.id}
                style={{
                  border: "1px solid #0075FF",
                  background: "#f2f8ff",
                  padding: "25px 22px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <img
                      className="mt5"
                      src={element.type == "paypal" ? paypalIcon : bankIcon}
                      width={"35px"}
                      alt=""
                    />
                  </div>
                  <div className="ml-10">
                    <span className="grey-b">{element.account_holder}</span>
                    {element.type == "bank_account" && <br></br>}
                    {element.type == "bank_account" && (
                      <IBANMasked iban={element.account_number} />
                    )}
                    {element.type == "paypal" && (
                      <span className="grey-b">
                        {element.email ? element.email : element.phone}
                      </span>
                    )}
                  </div>
                  <div style={{ flex: "1", textAlign: "end" }}>
                    <img
                      width={35}
                      src={
                        selectedAccount?.id == element.id
                          ? selectedTick
                          : nonSelectedTick
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-30 t-center mb-20">
          <Button
            onClick={selectAccount}
            variant="primary"
            type="submit"
            disabled={!selectedAccount}
            style={{ width: "100%", borderRadius: "21px" }}
          >
            {" "}
            Continue{" "}
          </Button>{" "}
        </div>
      </>
    );
  };

  const BankDetailsFormTemplate = () => {
    const [isLoading, setLoading] = useState();

    const createBankAccount = (e) => {
      setLoading(true);
      let data = e;
      // data['affiliateUserId'] = '1';
      data["type"] = "bank_account";

      axios
        .post(`affiliate/add-bank-details`, data)
        .then((res) => {
          setLoading(false);
          let response = res.data;
          if (response.statusCode === 200) {
            setSelectedBankAcc(response.data);
            setCurrentScreen("sendAmount");
          }
        })
        .catch((error) => {
          // toastr.error(error.message);
          // setLoading(false);
        });
    };

    return (
      <div>
        {isLoading && <LoadingOverlay />}
        <h6>Enter Bank Details</h6>
        <Form
          onFinish={createBankAccount}
          name="simpleForm"
          layout="vertical"
          initialValues={{ bankName: "" }}
        >
          <Form.Item
            name="bankName"
            className="mb-3"
            rules={[{ required: true, message: "Bank Name is required" }]}
          >
            <div>
              <label>Bank Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Bank Name"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="accountHolderName"
            className="mb-3"
            rules={[{ required: true, message: "Account Holder is required" }]}
          >
            <div>
              <label>Account Holder</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account Holder"
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="iban"
            className="mb-3"
            rules={[{ required: true, message: "IBAN is required" }]}
          >
            <div>
              <label>IBAN</label>
              <input type="text" className="form-control" placeholder="IBAN" />
            </div>
          </Form.Item> */}
          <Form.Item
            name="account_number"
            className="mb-3"
            rules={[{ required: true, message: "Account Number is required" }]}
          >
            <div>
              <label>Account Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Account Number"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="routing_number"
            className="mb-3"
            rules={[{ required: true, message: "Routing Number is required" }]}
          >
            <div>
              <label>Routing Number</label>
              <input
                type="number"
                className="form-control"
                placeholder="Routing Number"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="address"
            className="mb-3"
            rules={[{ required: true, message: "Address is required" }]}
          >
            <div>
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
              />
            </div>
          </Form.Item>

          <div className="mt-30 t-center ">
            <Button
              variant="primary"
              type="submit"
              style={{ width: "100%", borderRadius: "21px" }}
            >
              {" "}
              Continue{" "}
            </Button>{" "}
          </div>
        </Form>
      </div>
    );
  };

  const PaypalFormTemplate = () => {
    const [type, settype] = useState("phone");
    const [isLoading, setLoading] = useState();
    const [value, setValue] = useState();
    let email = null;
    let phone = null;

    const restrictAlphaphets = (event) => {
      const value = event.target.value.replace(/[^0-9+]/g, ""); // Remove non-numeric characters
      event.target.value = value;
    };

    const createPaypalAccount = (e) => {
      setLoading(true);
      let data = e;
      // data['affiliateUserId'] = '1';
      data["type"] = "paypal";

      axios
        .post(`affiliate/add-bank-details`, data)
        .then((res) => {
          let response = res?.data;
          setLoading(false);
          if (response?.statusCode === 200) {
            setSelectedBankAcc(response.data);
            setCurrentScreen("sendAmount");
          }
        })
        .catch((error) => {
          // toastr.error(error.message);
          setLoading(false);
        });
    };

    const handleChange = (e) => {
      settype(e.target.value);
      // email = e.target.value == 'email' ? e.target.value : null;
    };

    return (
      <div>
        {isLoading && <LoadingOverlay />}
        {/* <h6>Enter Bank Details</h6> */}
        <Form
          onFinish={createPaypalAccount}
          name="simpleForm"
          layout="vertical"
        >
          <div className="form-group">
            <label>Select Account Detail Type</label>
            <div>
              <Radio.Group
                name="radiogroup"
                value={type}
                onChange={handleChange}
              >
                <Radio value={"phone"}>Phone</Radio>
                <Radio value={"email"}>Email</Radio>
              </Radio.Group>
            </div>
          </div>
          {type == "email" && (
            <Form.Item
              name="email"
              style={{ marginBottom: "10px" }}
              rules={[{ required: true, message: "Email is required" }]}
            >
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="johndoe@gmail.com"
                />
              </div>
            </Form.Item>
          )}

          {type == "phone" && (
            <Form.Item
              name="phone"
              style={{ marginBottom: "10px" }}
              rules={[{ required: true, message: "Phone is required" }]}
            >
              <div className="form-group">
                <label>Phone</label>
                <PhoneInput
                  type="text"
                  className="form-control"
                  value={value}
                  onChange={setValue}
                  placeholder="+928998989"
                />
              </div>
            </Form.Item>
          )}

          <div className="mt-30 t-center mb-20">
            <Button
              variant="primary"
              type="submit"
              style={{ width: "100%", borderRadius: "21px" }}
            >
              {" "}
              Continue{" "}
            </Button>{" "}
          </div>
        </Form>
      </div>
    );
  };

  const SendAmountTemplate = () => {
    const [isLoading, setLoading] = useState();

    const restrictAlphaphets = (event) => {
      let value = event.target.value;

      value = value.replace(/[^0-9.]/g, (match, index, original) => {
        // Allow only the first decimal point
        if (match === ".") {
          return original.indexOf(".") === index ? "." : "";
        }
        return "";
      });

      // Allow only two decimal places
      const parts = value.split(".");
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }

      // Ensure the value is at least 1
      value = parseFloat(value) < 1 ? "1" : value;

      // Check if the new value matches the restricted value
      if (Number(value) > props.balance) {
        return;
      }

      setAmount(value);
    };

    const twoDecimal = (num) => {
      return num ? Number(num).toFixed(2) : "0.00";
    };

    const requestForVerifyCode = () => {
      setLoading(true);

      let data = {
        amount: amount,
      };

      axios
        .post(`affiliate/withdraw-request-verification-code`, data)
        .then((res) => {
          let response = res.data;
          setLoading(false);
          if (response.statusCode === 200) {
            setCurrentScreen("verifyCode");
          }
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    };

    return (
      <div style={{ padding: "10px 25px" }}>
        {isLoading && <LoadingOverlay />}

        <h6>
          <strong>To</strong>
        </h6>

        <div
          style={{
            border: "1px solid #0075FF",
            background: "#f2f8ff",
            padding: "25px 22px",
            borderRadius: "8px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                src={selectedMethod == "bank" ? bankIcon : paypalIcon}
                width={"35px"}
                alt=""
              />
            </div>
            <div className="ml-10">
              {selectedBankAcc.type == "bank_account" && (
                <span className="grey-b">
                  {selectedBankAcc?.account_holder}
                </span>
              )}
              {selectedBankAcc.type == "bank_account" && <br></br>}
              {selectedBankAcc.type == "paypal" && (
                <span className="grey-b">
                  {selectedBankAcc?.email
                    ? selectedBankAcc?.email
                    : selectedBankAcc?.phone}
                </span>
              )}
              {selectedBankAcc.type == "bank_account" && (
                <IBANMasked iban={selectedBankAcc?.account_number} />
              )}
            </div>
          </div>
        </div>

        <div className="mt-20">
          <span>
            <strong>Enter Amount</strong>
          </span>
          <span className="f-right">
            <strong>Available Balance:</strong>{" "}
            <span className="light-grey">${twoDecimal(props.balance)}</span>
          </span>
        </div>

        <div className="t-center mt-30" style={{ fontSize: "30px" }}>
          <span style={{ marginLeft: "75px" }}>$</span>
          <input
            className="invisible-field"
            value={amount}
            onChange={restrictAlphaphets}
            autoFocus={true}
            type="text"
          />
          <div className="text-grey f-12">
            Minimum withdrawal amount<strong className="ml5">$1.00</strong>{" "}
          </div>
        </div>
        <div className="mt-30 t-center mb-20">
          <Button
            onClick={requestForVerifyCode}
            variant="primary"
            disabled={!amount}
            style={{ width: "100%", borderRadius: "21px" }}
          >
            {" "}
            Withdraw{" "}
          </Button>{" "}
        </div>
      </div>
    );
  };

  const VerificationCodeTemplate = () => {
    const [verificationCode, setVerificationCode] = useState([
      "",
      "",
      "",
      "",
      "",
      "",
    ]);
    const [time, setTime] = useState({ minutes: 15, seconds: 0 });
    const [code, setCode] = useState(0);
    const inputRefs = [];

    useEffect(() => {
      const interval = setInterval(() => {
        if (time.minutes === 0 && time.seconds === 0) {
          clearInterval(interval);
        } else {
          if (time.seconds === 0) {
            setTime({ minutes: time.minutes - 1, seconds: 59 });
          } else {
            setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [time]);

    // Format minutes and seconds with leading zeros
    const formattedTime = `${String(time.minutes).padStart(2, "0")}:${String(
      time.seconds
    ).padStart(2, "0")}`;

    const handleInputChange = (e, index) => {
      const value = e.target.value;
      if (value.length === 1 && index < 5) {
        inputRefs[index + 1].focus();
      }
      const updatedCode = [...verificationCode];
      updatedCode[index] = value;
      setVerificationCode(updatedCode);

      // Check if all fields are filled and invoke the callback
      if (updatedCode.every((code) => code.length === 1 && code != "")) {
        setCode(String(updatedCode.join("")));
      } else {
        setCode(null);
      }
    };

    const handleFocus = (index) => {
      const updatedCode = [...verificationCode];
      updatedCode[index] = "";
      setVerificationCode(updatedCode);
    };

    const withdrawAmount = () => {
      let data = {
        verificationCode: code,
        amount: amount,
        bankDetailId: selectedBankAcc.id,
      };

      axios
        .post(`affiliate/withdraw-request`, data)
        .then((res) => {
          let response = res?.data;
          // setLoading(false);
          if (response.statusCode === 200) {
            setTransactionData(response.data.transaction);
            setCurrentScreen("moneySent");
          }
        })
        .catch((error) => {
          message.error(error.response.data.message);
          setCurrentScreen("moneySent");
          // toastr.error(error.message);
          // setLoading(false);
        });
    };

    const resendCode = () => {
      // setLoading(true);

      let data = {
        amount: amount,
      };

      axios
        .post(`affiliate/withdraw-request-verification-code`, data)
        .then((res) => {
          let response = res.data;
          // setLoading(false);
          if (response.statusCode === 200) {
            message.success("Code sent to email");
          }
        })
        .catch((error) => {
          // toastr.error(error.message);
          // setLoading(false);
        });
    };

    return (
      <div style={{ padding: "10px 25px" }}>
        <h6>
          <strong>To</strong>
        </h6>

        <div
          style={{
            border: "1px solid #0075FF",
            background: "#f2f8ff",
            padding: "25px 22px",
            borderRadius: "8px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                src={selectedMethod == "bank" ? bankIcon : paypalIcon}
                width={"35px"}
                alt=""
              />
            </div>
            <div className="ml-10">
              {selectedBankAcc.type == "bank_account" && (
                <span className="grey-b">
                  {selectedBankAcc?.account_holder}
                </span>
              )}
              {selectedBankAcc.type == "paypal" && (
                <span className="grey-b">
                  {selectedBankAcc?.email
                    ? selectedBankAcc?.email
                    : selectedBankAcc?.phone}
                </span>
              )}
              {selectedBankAcc.type == "bank_account" && <br></br>}
              {selectedBankAcc.type == "bank_account" && (
                <IBANMasked iban={selectedBankAcc?.account_number} />
              )}
              {/* {selectedBankAcc.type == 'paypal' && <span className='f-12'>{selectedBankAcc?.email ? selectedBankAcc?.email : selectedBankAcc?.phone}</span>} */}
            </div>
          </div>
        </div>

        <Row justify="space-between" className="mt-20 mb-20">
          <Col className="f-16">
            <strong>Amount</strong> <br></br>
            <span className="light-grey f-14">
              The Amount will be transferred to your account
            </span>
          </Col>
          <Col className="f-16">
            <div className="mt5">
              <strong>${amount || 0}</strong>
            </div>
          </Col>
        </Row>

        <div className="greyBackground"></div>
        <div style={{ position: "relative", zIndex: "1", marginTop: "50px" }}>
          <div>
            <h6>Enter Verification Code</h6>
            <span className="light-grey f-14">
              We have sent an 6-digit code to your registered email as stated
            </span>{" "}
            <br></br>
            <span className="light-grey f-14">
              here <span className="lightBlue">{user.userData.email}</span>
            </span>
          </div>

          <div className="mt-20">
            <OTPInput
              className="otp-input"
              value={code}
              onChange={setCode}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
          </div>

          <div className="t-center mt-20">
            Code expires in <span className="pinkRed">{formattedTime}</span>
          </div>

          <div className="mt-30 t-center mb-20">
            <Button
              onClick={withdrawAmount}
              variant="primary"
              disabled={!code}
              style={{ width: "100%", borderRadius: "21px" }}
            >
              {" "}
              Send for Approval{" "}
            </Button>{" "}
          </div>

          <div className="t-center mt-20">
            Didn't get a code?{" "}
            <span className="lightBlue cp" onClick={resendCode}>
              Resend.
            </span>
          </div>
        </div>
      </div>
    );
  };

  const MoneySentTemplate = () => {
    const [transactionDate, settransactionDate] = useState("");

    useEffect(() => {
      const date = moment(transactionData?.created_at); // Replace this with your date and timezone
      settransactionDate(date.format("D MMMM YYYY HH:mm [GMT] ZZ"));
    }, []);

    const twoDecimal = (num) => {
      return num ? Number(num).toFixed(2) : "0.00";
    };

    return (
      <div style={{ padding: "10px 25px" }}>
        <div className="t-center">
          <img src={tickIcon} alt="" />
          <div>
            <h4 className="bold">Congratulations</h4>
            <p className="light-grey">
              Your money will be transferred to your account in 1-2 business
              days once admin approves your request.
            </p>
          </div>
        </div>

        <div className="divider"></div>

        <Row className="mt-20" justify="space-between">
          <Col span={19}>
            <span className="bold">{transactionDate}</span> <br></br>
            <span className="light-grey">
              Transaction ID: {transactionData.id}
            </span>
          </Col>
          <Col span={5}>
            <Row justify="end" align={"middle"}>
              <Col>
                <span className="bold">${twoDecimal(amount)}</span> <br></br>
                <span className="app-orange-txt">{transactionData.status}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider></Divider>

        {selectedMethod == "bank" && (
          <>
            <h6 className="bold">Account Details</h6>
            <Row justify="space-between">
              <Col span={10}>
                <span className="light-grey">Bank</span> <br></br>
                <span className="light-grey">Account Holder</span> <br></br>
                <span className="light-grey">Account Number</span>
              </Col>
              <Col span={13}>
                <Row justify="end" align={"middle"}>
                  <Col style={{ textAlign: "end" }}>
                    <span className="bold">{selectedBankAcc?.bank_name}</span>{" "}
                    <br></br>
                    <span className="bold">
                      {selectedBankAcc?.account_holder}
                    </span>{" "}
                    <br></br>
                    <IBANMasked iban={selectedBankAcc?.account_number} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {selectedMethod == "paypal" && (
          <>
            <h6 className="bold">Paypal Details</h6>
            <Row justify="space-between">
              <Col span={10}>
                <span className="light-grey">
                  {selectedBankAcc?.email ? "Email" : "Phone"}
                </span>{" "}
                <br></br>
              </Col>
              <Col span={13}>
                <Row justify="end" align={"middle"}>
                  <Col style={{ textAlign: "end" }}>
                    <span className="bold">
                      {selectedBankAcc?.email
                        ? selectedBankAcc?.email
                        : selectedBankAcc?.phone}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        <div className="mt-30 t-center mb-20">
          <Button
            variant="primary"
            onClick={props.closeModal}
            type="submit"
            style={{ width: "100%", borderRadius: "21px" }}
          >
            {" "}
            Done{" "}
          </Button>{" "}
        </div>
      </div>
    );
  };

  return (
    <div key={props.id}>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ padding: "25px" }} closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            Withdraw Money
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentScreen == "paymentMethod" && <PaymentMethodTemplate />}
          {currentScreen == "bankForm" && <BankDetailsFormTemplate />}
          {currentScreen == "paypalForm" && <PaypalFormTemplate />}
          {currentScreen == "selectAccount" && <SelectBankAccountTemplate />}
          {currentScreen == "sendAmount" && <SendAmountTemplate />}
          {currentScreen == "verifyCode" && <VerificationCodeTemplate />}
          {currentScreen == "moneySent" && <MoneySentTemplate />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WithdrawMoneyDialog;
