import axios from "axios"; 

const instance = axios.create({
  baseURL : process.env.REACT_APP_NODE_API_URL,
  headers: {
//  Authorization: `<Your Auth Token>`,
   " Content-Type": "application/json",
    timeout : 1000,
  }, 
  // .. other options
});

instance.interceptors.request.use(
  (config) => {
    const Token = localStorage.getItem("token");
    // let x = JSON.parse(Auth);
    if (Token !== null) {
      config.headers["Token"] = Token; 
      config.headers["Content-Type"] = "application/json";
      // config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => {
//     if (response?.data?.responsecode === 101) {
//       localStorage.removeItem("AuthUser");
//       localStorage.removeItem("UserProfile");
//       return (window.location.href = 'main');
//     }  else {
//       return response;
//     }
//   },
//   (error) => {
//     toastr.error("Something went wrong");
//   }
// );

export default instance;