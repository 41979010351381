import React, { useRef, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Col, Divider, Row, Form } from 'antd';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import axios from './../../helper/api/api_instance';
import LoadingOverlay from '../../components/loader';

const Onboarding = ()=> {

    const [currentScreen, setCurrentScreen] = useState('verifyCode');
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '']);
    const [time, setTime] = useState({ minutes: 15, seconds: 0 });
    const [code, setCode] = useState(0);
    const [isCodeVerified, setCodeVerified] = useState(false);
    const inputRefs = [];
    const [isLoading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Trufit | Set Password';
        // Extract the query parameters from the location object
        const queryParams = new URLSearchParams(location.search);

        // Get the value of the 'Token' query parameter
        const token = queryParams.get('Token');
        console.log(token);
        // if (!token) {
        //     navigate('/auth/login');
        //     return;
        // }

        // localStorage.setItem('token', token);
        
    }, [])
    

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (value.length === 1 && index < 5) {
          inputRefs[index + 1].focus();
        }
        const updatedCode = [...verificationCode];
        updatedCode[index] = value;
        setVerificationCode(updatedCode);
    
        // Check if all fields are filled and invoke the callback
        if (updatedCode.every((code) => code.length === 1)) {
          setCode(String(updatedCode.join('')));
        }
      };
    
      const handleFocus = (index) => {
        const updatedCode = [...verificationCode];
        updatedCode[index] = '';
        setVerificationCode(updatedCode);
      };

      const goToPassword = (index) => {
        setCodeVerified(true)
      };

      const setPassword = (e) => {

        setLoading(true);
        let data = e;

        axios
        .post(`affiliate/set-new-password`,data)
        .then((res) => {
          console.log(res);
          setLoading(false);
          // setLoading(false);
          let response = res.data;
          if (response.statusCode === 200) {
            //  setCurrentScreen('sendAmount');
            // localStorage.clear();
            let updatedUser = {};
            updatedUser['authentication'] = JSON.parse(localStorage.getItem('AuthUser')).authentication;
            updatedUser['userData'] = response.data.user;
            localStorage.setItem('AuthUser', JSON.stringify(updatedUser));

            navigate('/main/dashboard');
          }
        })
        .catch((error) => {
          // toastr.error(error.message);
          setLoading(false);
        });
      };


  return (
    <>
    { isLoading && <LoadingOverlay /> }
    <div style={{ background: '#7e7e7f', width: '100%', height: '100vh', position: 'relative' }}>
        <div
        className="modal show"
        style={{'display' : 'block', position: 'absolute', top: '20%', left: '34%', width: '500px', height: 'auto', zIndex: '0'}}
        >
        <Modal.Dialog>
            <Modal.Header>
            <Modal.Title style={{fontSize: '18px', fontWeight: 'bold'}}> { isCodeVerified ? 'Create New Password' : 'User Profile Verification' } </Modal.Title>
            </Modal.Header>

            <Modal.Body>

            {/* { !isCodeVerified && 
                <>
                    <div>
                        <h6>Enter Verification Code</h6>
                        <span className='light-grey f-14'>We have sent an 6-digit code to your registered email as stated</span> <br></br>
                        <span className='light-grey f-14'>here <span className='lightBlue'>Jasonroy123@gmail.com</span></span>
                    </div>

                    <div style={{display: 'flex', gap: '12px', justifyContent: 'space-between'}} className='mt-20'>
                    <input className='verifyCodeInput' value={verificationCode[0]} 
                            onChange={(e) => handleInputChange(e, 0)} onFocus={() => handleFocus(0)}
                            ref={(input) => (inputRefs[0] = input)} autoFocus={true} type="text"/>

                    <input className='verifyCodeInput' value={verificationCode[1]} 
                            onChange={(e) => handleInputChange(e, 1)} onFocus={() => handleFocus(1)}
                            ref={(input) => (inputRefs[1] = input)} type="text"/>

                    <input className='verifyCodeInput' value={verificationCode[2]} 
                            onChange={(e) => handleInputChange(e, 2)} onFocus={() => handleFocus(2)}
                            ref={(input) => (inputRefs[2] = input)} type="text"/>

                    <input className='verifyCodeInput' value={verificationCode[3]} 
                            onChange={(e) => handleInputChange(e, 3)} onFocus={() => handleFocus(3)}
                            ref={(input) => (inputRefs[3] = input)} type="text"/>

                    <input className='verifyCodeInput' value={verificationCode[4]} 
                            onChange={(e) => handleInputChange(e, 4)} onFocus={() => handleFocus(4)}
                            ref={(input) => (inputRefs[4] = input)} type="text"/>

                    <input className='verifyCodeInput' value={verificationCode[5]} 
                            onChange={(e) => handleInputChange(e, 5)} onFocus={() => handleFocus(5)}
                            ref={(input) => (inputRefs[5] = input)} type="text"/>
                    </div>
                    <div className='mt-30 t-center mb-20'>
                        <Button onClick={goToPassword} variant="primary" type='submit' disabled={!code} style={{width: '100%', borderRadius: '21px'}}> Continue </Button>{' '}
                    </div>
                </>  
            } */}


            <div>
                <h6> <strong>Set your Password</strong> </h6>
                <span className='light-grey f-14'>Secure your account with a strong password, so you could login later</span> <br></br>
            </div>
            <Form onFinish={setPassword} name="simpleForm" className='mt-20' layout="vertical">
                <Form.Item name="password" style={{marginBottom: '10px'}} rules={[{ required: true, message: 'Password is required' }, {min: 8,message: 'Password must be at least 8 characters',}]}>
                <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control"/>
                    </div>
                </Form.Item>
                <Form.Item name="confirmPassword" style={{marginBottom: '10px'}} dependencies={['password']} hasFeedback rules={[{ required: true, message: 'Confirm Password is required' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject('The two passwords do not match!');
                    },
                }), ]}>
                <div className="form-group">
                        <label>Confirm Password</label>
                        <input type="password" className="form-control"/>
                    </div>
                </Form.Item>
                <div className='mt-30 t-center mb-20'>
                    <Button variant="primary" type='submit' style={{width: '100%', borderRadius: '21px'}}> Submit </Button>{' '}
                </div>
            </Form>

            </Modal.Body>

            {/* <Modal.Footer>
            <Button variant="secondary">Close</Button>
            <Button variant="primary">Save changes</Button>
            </Modal.Footer> */}
        </Modal.Dialog>
        </div>
    </div>

    </>
  )
}

export default Onboarding