import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, message } from "antd";
import axios from "./../../helper/api/old_api_instance";
import { useNavigate, useLocation } from "react-router-dom";
import OTPInput from "otp-input-react";
import AuthFrame from "../../components/AuthFrame";

const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);

  const OtpComp = () => {
    const [loading, setLoading] = useState(false);
    const [OTP, setOTP] = useState("");

    const reSendOtp = () => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", location?.state?.email);
      axios
        .post(`affiliate/forgot_password`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            message.success("OTP request sent");
            setLoading(false);
          } else {
            message.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
            message.error(error.message);
          setLoading(false);
        });
    };

    const onSubmit = () => {
      if (OTP !== "") {
        setLoading(true);
        const fd = new FormData();
        fd.append("user_email_id", location?.state?.email);
        fd.append("verify_code", OTP);
        axios
          .post(`affiliate/verify_code`, fd)
          .then((res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              setLoading(false);
              navigate(`/auth/new-password`, {
                state: { email: location?.state?.email },
              });
            } else {
                message.error(res.data.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            message.error(error.message);
            setLoading(false);
          });
      }
    };
    return (
      <>
        <p className="login-des mx-4 mt-2" style={{ color: "#0A364B" }}>
          Enter the Verification code we just sent on your email address.
        </p>
        <div className="mt-4">
          <OTPInput
            className="otp-input"
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />
<div className="mt-30 t-center">
          
          <Button
            variant="primary"
            type="submit"
            onClick={() => onSubmit()}
            style={{ width: "90%", borderRadius: "5px" }}
          >
            Verify
          </Button>
          {!loading && (
            <p className="no-accout mt-2">
              Haven’t received OTP?&nbsp;
              <strong className="yellow-underline" onClick={() => reSendOtp()}>
                Resend OTP
              </strong>
            </p>
          )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <AuthFrame loading={isLoading} myComp={OtpComp} />
    </>
  );
};

export default Otp;
