import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form, message } from 'antd';
import mailIcon from './../../assets/images/mail.svg';
import lockIcon from './../../assets/images/lock.svg';
import axios from './../../helper/api/api_instance';
import { useNavigate } from "react-router-dom";
import AuthFrame from '../../components/AuthFrame';

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Trufit | Login';
        if (localStorage.getItem('AuthUser')) {
            navigate('/main/dashboard');
        }
    }, [])

    const LoginComp = () => {
       
    const [messageApi, contextHolder] = message.useMessage();

        const login = (e) => {

            setLoading(true);
            let data = e;
    
            axios
                .post(`affiliate/login`, data)
                .then((res) => {
                    let response = res.data;
                    setLoading(false);
                    // setLoading(false);
                    if (response.statusCode === 200) {
                        localStorage.setItem('AuthUser', JSON.stringify(response.data));
                        localStorage.setItem('token', response.data.authentication);
    
                        if (response.data.userData.is_new_password == 1) {
                            navigate("/main/dashboard");
                        } else {
                            navigate("/auth/set-password");
                        }
                        //  setCurrentScreen('sendAmount');
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    message.error(error.response.data.message);
                });
        };
    
        return(
<Form onFinish={login} name="simpleForm" layout="vertical">
                        <Form.Item name="email" style={{ marginBottom: '20px' }} rules={[{ required: true, message: 'Email is required' }, { type: 'email', message: 'Please enter a valid email address!' }]}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <img width={20} src={mailIcon} alt="" />
                                    </span>
                                </div>
                                <input type="text" placeholder='Email' className="form-control" />
                            </div>
                        </Form.Item>
                        <Form.Item name="password" style={{ marginBottom: '10px' }} rules={[{ required: true, message: 'Password is required' }]}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">
                                        <img width={20} src={lockIcon} alt="" />
                                    </span>
                                </div>
                                <input type="password" placeholder='Password' className="form-control" />
                            </div>
                        </Form.Item>
                        <p
              className="forgot-pas-desc mt-2 "
              onClick={() => navigate(`/auth/forgot`)}
            >
              Forgot Password?
            </p>
                        <div className='mt-30 t-center'>
                            <Button variant="primary" type='submit' style={{ width: '100%', borderRadius: '5px' }}> Login </Button>{' '}
                        </div>
                    </Form>

        )
    }

    return (
        <>

        <AuthFrame loading={isLoading} myComp={LoginComp}/>


        </>
    )
}

export default Login