// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import { Link, Outlet } from "react-router-dom";
// import Dashboard from './Dashboard/dashboard';
// import Wallet from './Wallet/wallet';
// import toggleIcon from './../assets/images/toggle.png';
// import expandIcon from './../assets/images/expand.png';
// import { Divider, Row, Col } from 'antd';
// import FeatherIcon from 'feather-icons-react';
import './main-template.css';

import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "./../components/header";
import SiderMenu from "./../components/sider/SiderMenu";
const { Content, Sider } = Layout;


// const MainApp = () => {

//     function togglePanel() {
//         const sidePanel = document.querySelector('.side');
//         sidePanel.style.width = '400px';
//     }

//   return (
//     <>
    
//     <nav style={{zIndex: 1028}}>
        
//         <div className='navWrapper row'>
//             <div className='col-md-2' style={{background: '#263544', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '10px', paddingRight: '10px', borderBottom: '1px solid white', position: 'relative', textAlign: 'center'}}>
//                 <span style={{fontSize: '22px', color: 'white'}}>TRUFIT</span> 
//                 <img style={{position: 'absolute', right: '10px', top: '20px', width: '23px'}} src={toggleIcon} />
//             </div>
//             <div className='col-md-10 head' style={{padding: '20px'}}>
//                 <Row justify="space-between">
//                     <Col span={8}>
//                         <img src={expandIcon} alt="" />
//                     </Col>
//                     <Col span={15}>
//                         <Row justify="end" align={'middle'}>
//                             <img width={37} height={37} style={{marginRight: '10px'}} className='img-radius' src="https://api-staging.gettrufit.com/public/assets/images/app_icon.png" alt=""></img>
//                             <span>Admin</span> 
//                         </Row>
//                     </Col>
//                 </Row>
//             </div>
//         </div>

//         <div className='navWrapper row'>
//             <div style={{background: '#263644'}} className='col-md-2'>
//                 <ul className='sidenav-list'>
//                     <li style={{ padding: '7px 10px 7px 20px'}}> 
//                         <Link to="dashboard" > 
//                             <FeatherIcon icon="copy" style={{width: '15px'}} /> 
//                             <span className='ml-10'>Dashboard</span> 
//                         </Link>
//                     </li>
//                     <li style={{ padding: '7px 10px 7px 20px'}}>
//                         <Link to="wallet">
//                             <FeatherIcon icon="credit-card" style={{width: '15px'}} /> 
//                             <span className='ml-10'>Wallet</span>
//                         </Link>
//                     </li>
//                     <li style={{ padding: '7px 10px 7px 20px'}}>
//                         <Link to="setting"> 
//                             <FeatherIcon icon="settings" style={{width: '15px'}} />
//                             <span className='ml-10'>Settings</span>
//                         </Link>
//                     </li>
//                 </ul>
//             </div>
//             <div style={{height: 'calc(100vh - 69px)', padding: '0px 25px', backgroundColor: '#f2f7fb'}} className='col-md-10'>
//                 <Outlet></Outlet>                
 
//             </div>
//         </div>

//     </nav>

//     </>
//   )
// }



const MainApp = ({ is_feed }) => {

const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Header 
        collapsed={collapsed} setCollapsed={setCollapsed}
         />
        <Layout>
          <Sider
            style={{
              paddingInline: "0px",
              // width: '250px !important',
              display: collapsed ?  "none"  : 'block', 
              // transform: collapsed ? 'translateX(-250px)' : 'translateX(0)' 
            }}
            trigger={null}
            collapsible
            collapsed={collapsed}
            // className={collapsed ? 'goBehind' : 'goFront'}
          >
            <SiderMenu />
            {/* <p>dfddffdfddf</p> */}
          </Sider>
          <Layout>
            
            <Content
              style={{
                margin: 0,
                padding: '1.5rem 1.5rem 0px 1.5rem'
                // backgroundColor: "#FFFFFF",
              }}
            >

              <Outlet />

            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default MainApp