import React, {useEffect, useState} from "react";
import ToggleOn from "../assets/images/icn_toggle_on.png";
import ExpandIcon from "../assets/images/icn_expand.png";
import MinimizeIcon from "../assets/images/minimize.png";
import LogoutIcon from "../assets/images/log-out.svg";
import Profile from "../assets/images/icn_profile.png";
import ArrowDown from "../assets/images/icn_arrow_down.png";
import changePassword from '../assets/images/icn_change_password.png'
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import FormModal from '../components/FormModal';
import ChangePassword from '../components/ChangePassword';

const Header = ({collapsed, setCollapsed}) => {
  const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const user = JSON.parse(localStorage.getItem('AuthUser'));

    const requestFullScreen = () => {
      const element = document.documentElement; // The root element of the document
  
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    };

    const logout = () => {
      localStorage.clear();
      navigate('/auth/login');
    }

    useEffect(() => {
      const handleFullscreenChange = () => {
        setIsFullscreen(document.fullscreenElement !== null);
      };
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      return () => {
        document.removeEventListener("fullscreenchange", handleFullscreenChange);
      };
    }, []);

    const toggleFullscreen = () => {
      if (isFullscreen) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen().catch((error) => {
          console.error("Error attempting to enable full-screen mode:", error);
        });
      }
    };
  
    const items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => setVisible(true)}
              className="d-flex align-items-center text-decoration-none"
            >
              <img src={changePassword} alt="edit" style={{ height: "16px" }} />
              <span className="mx-2 dropdown-item">Change Password</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={logout}
              className="d-flex align-items-center text-decoration-none"
            >
              <img src={LogoutIcon} alt="delete" style={{ height: "18px" }} />
              <span className="mx-2 dropdown-item">Logout</span>
            </div>
          ),
        }
    ];
  return (
    <>
      <div className="header-main-div">
        <div className="element-space-between" style={{ backgroundColor: "#263644" }}>
          <div className="trufit-heading-div">
            <p className="trufit-header-heading m-0">TRUFIT</p>
          </div>
          <img src={ToggleOn} className="mx-2 cp" onClick={() => setCollapsed(!collapsed)} />
        </div>
        <div
          className="trufit-header-div element-space-between px-4"
          style={{ backgroundColor: "#FFFFFF" }}
        >
            <img className="mx-2 cp" src={isFullscreen ? MinimizeIcon : ExpandIcon}  onClick={toggleFullscreen} />

            <Dropdown
            menu={{
              items,
            }}
            overlayClassName="profile-dropdown"
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
        <div className="d-flex align-items-center mx-3 cp">
            <img src={Profile} />
            <p className="mx-2 my-0 profile-name">{user?.userData?.user_name}</p>
            <img className="mx-1" src={ArrowDown}  height={15} width={15}/>
        </div>
        </Dropdown>
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
            <ChangePassword handleCancel={() => setVisible(false)} />
        }
      />

    </>
  );
};

export default Header;
