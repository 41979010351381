import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Divider, Form, Row, message } from "antd";
import Button from "react-bootstrap/Button";
import LoadingOverlay from "./loader";
import axios from "../helper/api/api_instance";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
const ShareCodeDialog = (props) => {
  const [isLoading, setLoading] = useState("");
  const [emails, setEmails] = React.useState([]);
  const [focused, setFocused] = React.useState(false);
  const onFinish = () => {
    const arrayAsString = JSON.stringify(emails);
    setLoading(true);
    axios
      .get(`affiliate/shareReferalCode?code=${props.code}&emails=${encodeURIComponent(arrayAsString)}`)
      .then((res) => {
        let response = res?.data;
        setLoading(false);
        if (response?.statusCode === 200) {
          setEmails([]);
          props.closeModal();
          message.success("Emails sent");
        }
      })
      .catch((error) => {
        // toastr.error(error.message);
        setLoading(false);
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share Referral Code
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoadingOverlay />}

        <label>Emails:</label>
        <ReactMultiEmail
          placeholder="Input your email"
          emails={emails}
          onChange={(_emails) => {
            setEmails(_emails);
          }}
          autoFocus={true}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          getLabel={(email, index, removeEmail) => {
            return (
              <div className="tag-item" key={index}>
                {email}
                <button
                  type="button"
                  className="button"
                  onClick={() => removeEmail(index)}
                >
                  &times;
                </button>
              </div>
            );
          }}
        />
        <Button
          disabled={emails?.length === 0}
          type="primary"
          className="mt-2"
          onClick={onFinish}
          style={{ width: "100%", borderRadius: "21px" }}
        >
          Send Code
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ShareCodeDialog;
