import React, { useState } from 'react';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { Link } from 'react-router-dom';
import walletIcon from './../../assets/images/wallet.svg';
import dashboardIcon from './../../assets/images/dashboard.svg';
import settingIcon from './../../assets/images/settings.svg';
import FeatherIcon from 'feather-icons-react';


const { Header, Sider, Content } = Layout;
const SiderMenu = () => {
  return (
    <>
    
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname.includes('dashboard') ? '1' : '2']}
          items={[
            {
              key: '1',
              icon: (<img src={dashboardIcon} />),
              label: (
                <Link to="dashboard" > 
                    {/* <FeatherIcon icon="copy" style={{width: '15px'}} />  */}
                    <span>Dashboard</span> 
                </Link>
              ),
            },
            {
              key: '2',
              icon: (<img src={walletIcon} />),
              label: (
                <Link to="wallet" > 
                    {/* <FeatherIcon icon="copy" style={{width: '15px'}} />  */}
                    <span>Wallet</span> 
                </Link>
              ),
            },
            // {
            //   key: '3',
            //   icon: <img src={settingIcon} />,
            //   label: (
            //     <Link to="settings" > 
            //         {/* <FeatherIcon icon="copy" style={{width: '15px'}} />  */}
            //         <span>Settings</span> 
            //     </Link>
            //   ),
            // },
            // {
            //   key: '4',
            //   icon: (<img src={walletIcon} />),
            //   label: (
            //     <Link to="affiliate" > 
            //         {/* <FeatherIcon icon="copy" style={{width: '15px'}} />  */}
            //         <span>Affiliate Users</span> 
            //     </Link>
            //   ),
            // },
            // {
            //   key: '5',
            //   icon: (<img src={walletIcon} />),
            //   label: (
            //     <Link to="withdraw" > 
            //         {/* <FeatherIcon icon="copy" style={{width: '15px'}} />  */}
            //         <span>Withdrawel Requests</span> 
            //     </Link>
            //   ),
            // },
          ]}
        />
    </>
  )
}

export default SiderMenu;
