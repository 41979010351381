import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, message } from "antd";
import mailIcon from "./../../assets/images/mail.svg";
import lockIcon from "./../../assets/images/lock.svg";
import axios from "./../../helper/api/old_api_instance";
import { useNavigate, useLocation } from "react-router-dom";
import AuthFrame from "../../components/AuthFrame";

const NewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Trufit | Login";
    if (localStorage.getItem("AuthUser")) {
      navigate("/main/dashboard");
    }
  }, []);

  const NewPasswordComp = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = (values) => {
        setLoading(true);
        const fd = new FormData();
        fd.append("user_email_id", location?.state?.email);
        fd.append("new_password", values?.newPassword);
        fd.append("confirm_new_password", values?.confirmPassword);
        axios
          .post(`affiliate/change_password`, fd)
          .then((res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              message.success("Password reset successfully");
              setLoading(false);
              navigate('/');
            } else {
              setLoading(false);
              message.error(res.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            message.error(error.message);
          });
      };
    return (
      <Form onFinish={onFinish} name="simpleForm" layout="vertical">
        <Form.Item
          name="newPassword"
          style={{ marginBottom: "10px" }}
          rules={[
            { required: true, message: "Password is required" },
            {
              min: 8,
              message: "Please enter minimum 8 character password",
            },
          ]}
        >
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <img width={20} src={lockIcon} alt="" />
              </span>
            </div>
            <input
              type="password"
              placeholder="Enter Password"
              className="form-control"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["newPassword"]}
          style={{ marginBottom: "10px" }}
          rules={[
            { required: true, message: "Please enter confirm password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password and confirm password must be same.")
                );
              },
            }),
          ]}
        >
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <img width={20} src={lockIcon} alt="" />
              </span>
            </div>
            <input
              type="password"
              placeholder="Confirm Password"
              className="form-control"
            />
          </div>
        </Form.Item>

        <div className="mt-30 t-center">
          <Button
            variant="primary"
            type="submit"
            style={{ width: "100%", borderRadius: "5px" }}
          >
            {" "}
            Reset Password{" "}
          </Button>{" "}
        </div>
      </Form>
    );
  };

  return (
    <>
      <AuthFrame loading={isLoading} myComp={NewPasswordComp} />
    </>
  );
};

export default NewPassword;
