import axios from "axios"; 

const instance = axios.create({
  baseURL : process.env.REACT_APP_API_URL,
  headers: {
//  Authorization: `<Your Auth Token>`,
   " Content-Type": "application/json",
    timeout : 1000,
  }, 
  // .. other options
});

instance.interceptors.request.use(
  (config) => {
    const Token = localStorage.getItem("token");
    // let x = JSON.parse(Auth);
    if (Token !== null) {
      config.headers["Token"] = Token; 
      config.headers["Content-Type"] = "application/json";
      // config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default instance;