import React, { useState } from "react";
import { Col, Divider, Row, Table, message, Pagination } from "antd";
import Button from "react-bootstrap/Button";
import "./dashboard.css";
import dashboardIcon from "./../../assets/images/dashboard-icon.png";
import shareEmailIcon from "./../../assets/images/icn_share_mail.png";
import copyIcon from "./../../assets/images/icn_copy_text.png";
import copyLinkIcon from "./../../assets/images/icn_copy_link.png";
import shareQrIcon from "./../../assets/images/icn_qr _code.png";
import dashboardMiniIcon from "./../../assets/images/dashboard.png";
import FeatherIcon from "feather-icons-react";
import axios from "./../../helper/api/api_instance";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import LoadingOverlay from "../../components/loader";
import ShareCodeDialog from "../../components/shareCodeDialog";
import QrDialog from "../../components/myQrDialog";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [referralList, setReferralList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [kpis, setKPIs] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({});
  const [chartConfig, setChartConfig] = useState({});
  const [stat, setStats] = useState({ trial: 0, subscribed: 0 });
  const [textToCopy, setTextToCopy] = useState("N/A");
  const [isCopied, setIsCopied] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [showSendCodeModal, setCodeModalShow] = useState(false);
  let pageNumber = 1;
  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 10,
  });

  let user = JSON.parse(localStorage.getItem("AuthUser"));

  const data = {
    // labels: ['Red', 'Blue'],
    datasets: [
      {
        // label: '# of Votes',
        data: [100],
        backgroundColor: ["grey"],
        borderColor: ["grey"],
        borderWidth: 1,
      },
    ],
  };
  const [chartData, setChartData] = useState(data);

  useEffect(() => {
    document.title = "Trufit | Dashboard";
    getRefferalList();
    getDashboardKPIs();
    // setChart();
  }, []);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
      status: "Free Trial",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
      status: "Subscribed",
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "user_id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email_id",
      key: "user_email_id",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      render: (text, record) => ({
        props: {
          style: {
            color: record.user_type == "Free Trial" ? "#F89728" : "#1A68C5",
          }, // Set the text color based on the "color" field
        },
        children: text,
      }),
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      render: (text, record) => <span> {twoDecimal(record.commission)} </span>,
    },
  ];

  const config = {
    labels: ["Red", "Blue"],
    datasets: [
      {
        data: [300, 50],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const handleCopyClick = (link) => {
    if (link !== "") {
      navigator.clipboard
        .writeText(link.concat(textToCopy))
        .then(() => {
          setIsCopied(true);
          message.info("Link copied");
        })
        .catch((error) => {
          console.error("Unable to copy to clipboard:", error);
        });
    } else {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setIsCopied(true);
          message.info("Referral code copied");
        })
        .catch((error) => {
          console.error("Unable to copy to clipboard:", error);
        });
    }
  };

  const getRefferalList = () => {
    setLoading(true);
    axios
      .get(
        `affiliate/dashboard-referral-list?id=1&per_page=10&page=${pageNumber}`
      )
      .then((res) => {
        setLoading(false);
        if (res?.data?.statusCode === 200) {
          setReferralList(res?.data?.data?.referral_users);
          let pagination = {
            pageSize: res?.data?.data?.pagination?.per_page,
            total: res?.data?.data?.pagination?.count,
            current: res?.data?.data?.pagination?.page,
          };
          setPagination(pagination);
        }
      })
      .catch((error) => {
        // toastr.error(error.message);
        setLoading(false);
      });
  };

  const getDashboardKPIs = () => {
    setLoading(true);
    axios
      .get(`/affiliate/dashboard?id=1`)
      .then((res) => {
        let response = res.data;
        setKPIs(response?.data?.kpis);
        if (response?.data?.kpis) {
          let freeTrialCount = response?.data?.kpis?.free_trial_count
            ? response?.data?.kpis?.free_trial_count
            : 0;
          let subscribeCount = response?.data?.kpis?.subscribed_count
            ? response?.data?.kpis?.subscribed_count
            : 0;

          let updatedData = {
            // labels: ['Red', 'Blue'],
            datasets: [
              {
                // label: '# of Votes',
                data: [freeTrialCount, subscribeCount],
                backgroundColor: ["#F89728", "#1A68C5"],
                borderColor: ["#F89728", "#1A68C5"],
                borderWidth: 1,
              },
            ],
          };
          setChartData(updatedData);
        }
        setLoading(false);
        setTextToCopy(response?.data?.kpis?.referral_code);
        setStats({
          trial: response?.data?.kpis?.free_trial_count,
          subscribed: response?.data?.kpis?.subscribed_count,
        });
      })
      .catch((error) => {
        // toastr.error(error.message);
        setLoading(false);
      });
  };

  const onPaginate = (number) => {
    pageNumber = number;
    setPagination({ ...pagination, current: number });
    getRefferalList();
  };

  const showEmailModal = () => {
    setCodeModalShow(true);
  };

  const setChart = () => {
    // Data for the doughnut chart
    let config = {
      labels: ["Red", "Blue"],
      datasets: [
        {
          data: [300, 50],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };
    setChartConfig(config);
  };

  const twoDecimal = (num) => {
    return num ? Number(num).toFixed(2) : "0.00";
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}

      <Row justify="space-between" align={"middle"} className="mt-30">
        <Col span={15}>
          <div>
            <img width={40} src={dashboardIcon}></img>
            <span className="ml-10">Dashboard</span>
          </div>
        </Col>
        <Col span={8}>
          <div style={{ textAlign: "end" }}>
            <img src={dashboardMiniIcon} style={{ width: "15px" }} />
            <span> / Dashboard</span>
          </div>
        </Col>
      </Row>

      <Row className="mt-30" style={{ gap: "38px" }}>
        <Col style={{ flex: "1" }}>
          <div style={{ height: "185px" }} className="card">
            <span className="card-head">Earnings</span>
            <Divider style={{ margin: "13px 0" }}></Divider>
            <Row justify="space-between" align={"middle"}>
              <Col span={10}>
                <span className="readings">
                  $ {twoDecimal(kpis?.earning) || 0.0}
                </span>
              </Col>
              <Col span={10} style={{ textAlign: "right" }}>
                <span style={{ color: "#FF0000" }}>
                  {twoDecimal(kpis?.last_month_earning_percentage)}%
                </span>
              </Col>
            </Row>
            <Row justify="space-between" align={"middle"}>
              <Col span={10}>
                <span style={{ fontSize: "14px" }} className="grey">
                  Last Month Income
                </span>
              </Col>
              <Col span={10} style={{ textAlign: "right" }}>
                <span style={{ fontSize: "20px" }} className="grey">
                  {twoDecimal(kpis?.last_month_earning) || 0.0}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col style={{ flex: "1" }}>
          <div style={{ height: "185px" }} className="card">
            <span className="card-head">Referrals</span>
            <Divider style={{ margin: "13px 0" }}></Divider>
            <Row justify="space-between" align={"middle"}>
              <Col span={10}>
                <span className="readings">{kpis?.referrals_count || 0}</span>
              </Col>
              <Col span={10} style={{ textAlign: "right" }}>
                <span style={{ color: "#FF0000" }}>
                  {twoDecimal(kpis?.last_month_referral_percentage)}%
                </span>
              </Col>
            </Row>
            <Row justify="space-between" align={"middle"}>
              <Col span={12}>
                <span style={{ fontSize: "14px" }}>Last Month Referrals</span>
              </Col>
              <Col span={8} style={{ textAlign: "right" }}>
                <span style={{ fontSize: "20px", color: "#525256" }}>
                  {kpis?.last_month_referral_count || 0}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col style={{ flex: "1" }}>
          <div style={{ height: "185px" }} className="card">
            <span className="card-head">Referrals Code</span>
            <Divider style={{ margin: "13px 0" }}></Divider>
            <Row
              className="m-10"
              style={{ flexWrap: "nowrap", gap: "10px" }}
              justify={"space-between"}
              align={"middle"}
            >
              <Col
              style={{flexGrow: '1'}}
              >
                <div className="code-badge d-flex justify-content-between">
                  {textToCopy || "N/A"}
                  <div
                    style={{ borderLeft: "1px solid #E0F0FC" }}
                    className="mx-2"
                  >
                    <img
                      src={copyIcon}
                      className="mx-3"
                      height={25}
                      onClick={() => handleCopyClick("")}
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <Button variant="warning" className="app-orange-bg p-2">
                  <img
                    src={copyLinkIcon}
                    height={22}
                    alt=""
                    onClick={() =>
                      handleCopyClick(
                        "https://app-staging.staytrufit.com/signup?code="
                      )
                    }
                  />
                  {/* <span onClick={handleCopyClick} className='white ml5'>Copy</span> */}
                </Button>{" "}
              </Col>
              <Col>
                <Button
                  variant="primary"
                  onClick={showEmailModal}
                  disabled={!textToCopy || textToCopy == "N/A" ? true : false}
                  className="app-blue-bg p-2"
                >
                  <img src={shareEmailIcon} height={22} alt="" />
                </Button>{" "}
              </Col>
              <Col>
                <Button
                  variant="dark"
                  onClick={() => setShowQr(true)}
                  disabled={!textToCopy || textToCopy == "N/A" ? true : false}
                  className="app-black-bg p-2"
                >
                  <img src={shareQrIcon} height={22} alt=""/>
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row justify="space-between" className="mt-30">
        <Col span={15}>
          <div className="card" style={{ padding: 0 }}>
            <div className="table-heading bold">Referrals List</div>
            <Table
              columns={columns}
              dataSource={referralList}
              pagination={false}
              className="table-striped-rows"
            />

            {referralList.length != 0 && (
              <div style={{ padding: "15px", textAlign: "end" }}>
                <Pagination
                  onChange={(e) => onPaginate(e)}
                  total={pagination.total}
                  current={pagination.current}
                  pageSize={pagination.pageSize}
                />
              </div>
            )}
          </div>
        </Col>
        <Col span={8}>
          <div className="card">
            <h6 className="card-head">Free Trial vs Subscribed</h6>
            <Divider></Divider>

            <div className="chartWrap">
              <Doughnut data={chartData} />
            </div>

            <Row justify="space-between" align={"middle"} className="mt-30">
              <Col span={15}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "orange",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div className="ml-10 bold">Total Free Trial</div>
                </div>
              </Col>
              <Col span={8}>
                <div className="bold" style={{ textAlign: "end" }}>
                  <span> {stat.trial}</span>
                </div>
              </Col>
            </Row>
            <Row justify="space-between" align={"middle"} className="mt-30">
              <Col span={15}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "blue",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <span className="ml-10 bold">Total Subscribed</span>
                </div>
              </Col>
              <Col span={8}>
                <div className="bold" style={{ textAlign: "end" }}>
                  <span> {stat.subscribed}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <ShareCodeDialog
        show={showSendCodeModal}
        code={textToCopy}
        closeModal={() => setCodeModalShow(false)}
        onHide={() => setCodeModalShow(false)}
      />
       <QrDialog
        show={showQr}
        code={textToCopy}
        closeModal={() => setShowQr(false)}
        onHide={() => setShowQr(false)}
      />
      {/* <Pagination total={24} defaultPageSize={10} defaultCurrent={1} /> */}
    </>
  );
};

export default Dashboard;
