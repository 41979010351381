import React, { useState, useEffect } from 'react';
import { Col, Divider, Row, Table, DatePicker, Input, Pagination } from 'antd';
import Button from 'react-bootstrap/Button';
import './wallet.css';
import calendarIcon from '../../assets/images/calendar.png';
import searchIcon from '../../assets/images/search.png';
import walletIconLink from '../../assets/images/wallet-link.png';
import walletIcon from '../../assets/images/wallet-block.png';
import Dropdown from 'react-bootstrap/Dropdown'
import TransactionDetailDialog from '../../components/transactionDetailDialog';
import WithdrawMoneyDialog from '../../components/withdrawMoneyDialog';
import axios from './../../helper/api/api_instance';
import moment from 'moment/moment';
import upArrow from './../../assets/images/withdrawn-arrow.png';
import downArrow from './../../assets/images/earning-arrow.png';
import LoadingOverlay from '../../components/loader';

const { RangePicker } = DatePicker;

const Wallet = () => {

  const [balance, setBalance] = useState(0);
  const [transactionsList, setTransactionsList] = useState([]);
  const [transactionDetail, setTransactionsDetail] = useState({});
  const [pagination, setPagination] = useState({pageSize:10, current: 1, total: 10});
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  let transacType = '';
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [transactionType, setTransactionType] = useState('');
  let startDate = null;
  let endDate = null;
  const [startDateTransaction, setStartDateTransaction] = useState(null);
  const [endDateTransaction, setEndDateTransaction] = useState(null);
  // const [randomNum, setRandomNum] = useState(null);
  const [showModal, setWithdrawModalShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  let pageNumber = 1;

  useEffect(()=> {
    document.title = 'Trufit | Wallet';
    let user = JSON.parse(localStorage.getItem('AuthUser'));
    setBalance(user.userData.balance);
    getTransactionList();    
  }, [])

    const dataSource = [
        {
          key: "1",
          name: "Mike",
          age: 32,
          address: "10 Downing Street",
        },
        {
          key: "2",
          name: "John",
          age: 42,
          address: "10 Downing Street",
        },
        {
          key: "3",
          name: "Mike sam",
          age: 32,
          address: "10 Downing Street",
        },
        {
          key: "4",
          name: "John cena",
          age: 42,
          address: "10 Downing Street",
        },
      ];
    
      const columns = [
 
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          // render: (text, record) => ({
          //   props: {
          //     style: { color: record.type == 'Withdrawn' ? '#FF4D4D' : '#00DA57' }, // Set the text color based on the "color" field
          //   },
          //   children: text,
          // }),
          render: (text, record) => 
           (
            <span style={{color: record.type == 'Withdrawn' ? '#FF4D4D' : '#00DA57' }}>
              <img src={record.type == 'Withdrawn' ? upArrow : downArrow} alt="" />
              <span className='ml5'>{record.type}</span>
            </span>
          
          )
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
        },
        {
          title: "Amount",
          dataIndex: "commission_amount",
          key: "commission_amount",
          render: (text, record) => (<span> {twoDecimal(record.commission_amount)} </span>)
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (text, record) => ({
            props: {
              style: { color: record.status == 'Pending' ? '#F89728' : record.status == 'Failed' ? 'red' : '#00DA57' }, // Set the text color based on the "color" field
            },
            children: text,
          }),
        },
      ];

      const getTransactionList = () => {
        
        setLoading(true);
        let data = {
          // "search": search,
          "type": transacType,
          "dateFrom": startDate,
          "dateTo": endDate
        }

        if (transacType == 'All') {
          delete data['type'];
        }

        axios
          .post(`affiliate/transactions-list?per_page=10&page=${pageNumber}`,data)
          .then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              // debugger;
              // setBalance(res?.data?.data?.balance)
              let transactions =  res?.data?.data?.transactions;
              transactions.forEach((element, index) => {
                element['key'] = index;
                element['date'] = moment(element.created_at).format('D MMMM YYYY'); 
              });
              setTransactionsList(res?.data?.data?.transactions);
              setBalance(res?.data?.data?.balance);
              
              let pagination = {
                pageSize: res?.data?.data?.pagination?.per_page,
                total: res?.data?.data?.pagination?.count,
                current: res?.data?.data?.pagination?.page,
              }
              // debugger;
              setPagination(pagination);
            }
          })
          .catch((error) => {
            // toastr.error(error.message);
            setLoading(false);
          });
      };

      const onPaginate = (number) => {
         pageNumber = number;
         setPagination({...pagination, current: number});
         getTransactionList();
      }

      // Date Range Code

      const [modalShow, setModalShow] = React.useState(false);
      const [showWithdrawDialog, setWithdrawDialog] = React.useState(false);

      const [dates, setDates] = useState(null);
      const [value, setValue] = useState(null);

      const [datesTransaction, setDatesTransaction] = useState(null);
      const [value2, setValue2] = useState(null);

      const disabledDate = (current) => {
        // if (!dates) {
        //   return false;
        // }
        // const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
        // const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
        // return !!tooEarly || !!tooLate;
      };

      const onOpenChange = (open) => {
        // if (open) {
        //   setDates([null, null]);
        // } else {
        //   setDates(null);
        // }
      };

      // End


    //   const CustomCalendarIcon = (
    //     <img
    //       src={'./../../assets/images/calendar.png'}
    //       alt="Custom Calendar Icon"
    //     > </img>
    //   );

      const openWithdraw = () => {
        setWithdrawModalShow(true);
        setWithdrawDialog(true);
      };

      const closeWithdraw = () => {
        setWithdrawDialog(false);
        setTimeout(()=> {
          setWithdrawModalShow(false);
          getTransactionList();
        }, 1000);
      };

      const onRangeChange = (dates, dateStrings) => {
        if (dates) {
          startDate = dateStrings[0];
          endDate = dateStrings[1];
          // setStartDate(start);
          // setEndDate(end);
          getTransactionList();
        }
      };

      const typeChange = (e) => {

        setType(e.target.value);
        transacType = e.target.value;
        if (e.target.value) {
          getTransactionList();
        }
      };

      const onRangeChange2 = (dates, dateStrings) => {
        
        if (dates) {
          setStartDateTransaction(dateStrings[0]);
          setEndDateTransaction(dateStrings[1]);
        }
      };

      const twoDecimal = (num)=> {
        return  num ? Number(num).toFixed(2) : '0.00';
      }

      const generateReport = () => {

        setLoading(true);

        let data = {
          "type": transactionType,
          "dateFrom": startDateTransaction,
          "dateTo": endDateTransaction
        }

        if (transactionType == 'All') {
          delete data['type'];
        }

  
        axios
        .post(`affiliate/transactions-report`,data)
        .then((res) => {
          let response = res.data;
          if (response.statusCode === 200) {
            let url = `https://trufit-bucket.s3.amazonaws.com/staging/${response.data.csvFileName}`;
            downloadFile(url);
            setLoading(false);

            //  setCurrentScreen('sendAmount');
          }
        })
        .catch((error) => {
          // toastr.error(error.message);
          setLoading(false);
        });
      }

      const downloadFile = (url) => {
        const fileUrl = url; // Replace with your file URL
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Report.csv'; // Provide a name for the downloaded file
        link.click();
      };

        // Set the `onRow` property to specify the custom click handler
        const rowSelection = {
          onRow: (record) => ({
            onClick: () => setWithdrawDialog(true),
          }),
        };
    
      const actionOption = [
        // {
        //   name: "edit",
        //   icon: "",
        // },
        // {
        //   name: "delete",
        //   icon: "",
        // },
      ];

  return (
    <>

    { isLoading && <LoadingOverlay /> }

    <Row justify="space-between" align={'middle'} className='mt-30'>
      <Col span={15}>
        <div>
           <img width={40} src={walletIcon}></img>
           <span className='ml-10'>Wallet</span>
        </div>
      </Col>
      <Col span={8}>
        <div style={{textAlign: 'end'}}>
            <img src={walletIconLink} style={{width: '15px'}} />
            <span> &nbsp;/&nbsp; Wallet</span>
        </div>
      </Col>
    </Row>

    <Row justify="space-between" className='mt-30'>
      <Col span={6}>
        <div className='card app-blue-bg' style={{ height: '250px', color: 'white'}}>
            <h6 className='card-head'>Balance</h6>
            <Divider style={{margin: '13px 0'}}></Divider>
            <div style={{textAlign: 'center'}} className='m-20'>
                <span style={{margin: '13px 0', fontSize: '30px'}}>$ {twoDecimal(balance)}</span>  <br></br>
                <span>Available</span> 
            </div>
            <div style={{textAlign: 'center', paddingTop: '12px'}}>
            <Button variant="light" onClick={openWithdraw} style={{width: '100%'}}> Withdraw </Button>{' '}
            </div>
        </div>

        <div className='card mt-30'>
            <h6 className='card-head'>Reports</h6>

            <h6 className='mt-20'>Transaction Type</h6>

            <div style={{height: '50px'}} className="form-group m-10">
              {/* <label>Disabled select menu</label> */}
              <select value={transactionType} placeholder='Select Transaction' onChange={(e)=> setTransactionType(e.target.value)} className="form-control custom-select cp">
                <option value="" style={{color: '#d9d9d9'}} disabled>Select Transaction</option>
                <option value={'All'}>All Transaction</option>
                <option value={'Commission'}>Commission</option>
                <option value={'Withdrawn'}>Withdrawn</option>
                <option value={'Earning'}>Earning</option>
              </select>
            </div>

            <h6>Date Range</h6>

            {/* <DatePicker onChange={onChange} className='calendar m-10' suffixIcon={<img src={calendarIcon} />} /> */}
            <RangePicker style={{height: '50px'}} onChange={onRangeChange2} className='m-10' suffixIcon={<img src={calendarIcon} />}
                              value={datesTransaction || value2}
                              disabledDate={disabledDate}
                              onCalendarChange={(val) => {
                                setDatesTransaction(val);
                              }}
                              onOpenChange={onOpenChange}
                              changeOnBlur
                            />


            <Button variant="warning" onClick={generateReport} disabled={!transactionType || !datesTransaction} style={{width: '100%', height: '50px'}} className='m-10 app-orange-bg white'>Download Report</Button>{' '}

            {/* <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-arrow">
                    Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item >Action</Dropdown.Item>
                    <Dropdown.Item >Another action</Dropdown.Item>
                    <Dropdown.Item >Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
        </div>
      </Col>
      <Col span={17}>
         <div className='card' style={{padding: 0}}>
            <Row style={{paddingRight: '10px'}} justify={'space-between'} align={'middle'}>
              <Col>
                  <div className='table-heading'>
                  Transactions
                  </div>
              </Col>
              <Col style={{display: 'flex', gap: '10px', paddingTop: '15px'}}>
                {/* <DatePicker onChange={onChange} className='m-10' suffixIcon={<img src={calendarIcon} />} /> */}
                <RangePicker style={{width: '250px'}} onChange={onRangeChange} className='m-10' suffixIcon={<img src={calendarIcon} />}
                              value={dates || value}
                              disabledDate={disabledDate}
                              onCalendarChange={(val) => {
                                setDates(val);
                              }}
                              onOpenChange={onOpenChange}
                              changeOnBlur
                            />
                {/* <Input className='m-10' style={{width: '250px', padding: '7px'}} placeholder='Search' suffix={<img src={searchIcon} />} /> */}
                <select value={type} style={{width: '250px', padding: '4px', height: '31px', fontSize: '13px', borderColor: '#d9d9d9'}} onChange={(e)=> typeChange(e)} className="form-control custom-select cp mt-10">
                    <option value="" style={{color: '#d9d9d9'}} disabled>Select Transaction</option>
                    <option value={'All'}>All Transaction</option>
                    <option value={'Commission'}>Commission</option>
                    <option value={'Withdrawn'}>Withdrawn</option>
                    <option value={'Earning'}>Earning</option>
                </select>
              </Col>
            </Row>

            <Table columns={columns} dataSource={transactionsList} pagination={false} onRow={(record) => {
              return {
                onClick: () => {setModalShow(true); setTransactionsDetail(record)},
              };
            }} className="table-transaction" />

           { 
           transactionsList.length != 0 && 
            <div style={{padding: '15px', textAlign: 'end'}}>
               <Pagination onChange={(e) => onPaginate(e)} total={pagination.total} current={pagination.current} pageSize={pagination.pageSize} />
            </div>
           }

         </div>


      </Col>
    </Row>

     {/* All Dialogs Here */}
    <TransactionDetailDialog
        show={modalShow}
        data= {transactionDetail}
        closeModal={() => setModalShow(false)}
        onHide={() => setModalShow(false)}
      />

    { 
      showModal && <WithdrawMoneyDialog show={showWithdrawDialog} onHide={closeWithdraw} balance={balance} closeModal={closeWithdraw}/>
    }


      {/* <Pagination total={24} defaultPageSize={10} defaultCurrent={1} /> */}

    </>
  )
}

export default Wallet