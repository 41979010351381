import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, message } from "antd";
import mailIcon from "./../../assets/images/mail.svg";
import lockIcon from "./../../assets/images/lock.svg";
import axios from "./../../helper/api/old_api_instance";
import { useNavigate } from "react-router-dom";
import AuthFrame from "../../components/AuthFrame";

const Forgot = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const ForgotComp = () => {
    const onFinish = (values) => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", values?.email);
      axios
        .post(`affiliate/forgot_password`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            setLoading(false);
            navigate(`/auth/otp`, { state: { email: values?.email } });
          } else {
            message.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    };

    return (
      <Form onFinish={onFinish} name="simpleForm" layout="vertical">
        <Form.Item
          name="email"
          style={{ marginBottom: "20px" }}
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address!" },
          ]}
        >
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                <img width={20} src={mailIcon} alt="" />
              </span>
            </div>
            <input type="text" placeholder="Email" className="form-control" />
          </div>
        </Form.Item>
        <div className="mt-30 t-center">
          <Button
            variant="primary"
            type="submit"
            style={{ width: "100%", borderRadius: "5px" }}
          >
            {" "}
            Send{" "}
          </Button>{" "}
          <p className="no-accout mt-2">
          Back to&nbsp;
          <strong onClick={() => navigate("/")} className="yellow-underline">
            Log in
          </strong>
        </p>
        </div>
       
      </Form>
    );
  };
  return (
    <>
      <AuthFrame loading={isLoading} myComp={ForgotComp} />
    </>
  );
};

export default Forgot;
