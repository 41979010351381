import { Form, Input, message } from "antd";
import React from "react";
import Button from "react-bootstrap/Button";
import EmailLock from "../assets/images/icn_lock.png";
import axios from "../helper/api/old_api_instance";
import { useState } from "react";
const ChangePassword = ({ handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const UserProfile = JSON.parse(
    localStorage.getItem("AuthUser")
  ).userData;

  const onFinish = (values) => {
    console.log('valuess===>>>', values);
    setLoading(true);
    const fd = new FormData();
    fd.append("password_status", "change_password_status");
    fd.append("user_email_id", UserProfile?.email);
    fd.append("old_password", values?.currentPassword);
    fd.append("new_password", values?.newPassword);
    fd.append("confirm_new_password", values?.confirmPassword);

    axios
      .post("affiliate/change_password", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          message.success("Your password has been updated");
          handleCancel();
          setLoading(false);
        } else {
          message.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="mt-4 text-center m-5" style={{ width: "400px" }}>
        <p className="card-head bold mt-4 mb-0">Change Password</p>
        <h6 className="mx-4 mt-2" style={{ color: "#0A364B" }}>
          Enter your current password and new password to change password.
        </h6>
        <Form
          name="login"
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
              {
                min: 8,
                message: "Current Password must be atleast 8 characters",
              },
            ]}
          >
            <Input.Password
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Enter Current Password"
              className="login-input mt-2"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            dependencies={["currentPassword"]}
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
              { min: 8, message: "New Password must be atleast 8 characters" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("currentPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New password cannot be the same as old password")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Enter Password"
              className="login-input mt-2"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword", "currentPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New Password and confirm password must be same.")
                  );
                },
                validator(_, value) {
                  if (!value || getFieldValue("currentPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New password cannot be the same as old password")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Confirm Password"
              className="login-input mt-2"
            />
          </Form.Item>
          <Button
            type="submit"
            className="btn btn-primary mt-2"
            style={{width:'100%'}}
            // loading={loading}
          >
            Update Password
          </Button>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
