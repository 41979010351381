import "./App.css";
import "./assets/styles/main.scss";
import {
  NavLink,
  Route,
  Routes,
  redirect,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import About from "./pages/About/About";
import MainApp from "./pages/main-template";
import Dashboard from "./pages/Dashboard/dashboard";
import Wallet from "./pages/Wallet/wallet";
import AffiliateUserListing from "./pages/Afiliate-Users/affiliate-user-listing";
import Onboarding from "./pages/Onboarding/onboarding";
import Login from "./pages/Login/login";
import Forgot from "./pages/ForgotPassword/forgot";
import AuthTemplate from "./pages/AuthTemplate/auth-template";
import { Alert, Space, Spin } from "antd";
import Otp from "./pages/ForgotPassword/Otp";
import NewPassword from "./pages/ForgotPassword/NewPassword";

function App() {
  let isLoading = false;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="auth/login" />} />

        <Route path="main" element={<ProtectedRoute element={MainApp} />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="settings" element={<About />} />
        </Route>

        <Route path="auth" element={<AuthTemplate />}>
          <Route index path="login" element={<Login />} />
          <Route index path="forgot" element={<Forgot />} />
          <Route index path="otp" element={<Otp />} />
          <Route index path="new-password" element={<NewPassword />} />
          <Route path="set-password" element={<Onboarding />}></Route>
        </Route>

        <Route
          path="*"
          element={<h1 className="t-center">Not Found</h1>}
        ></Route>
      </Routes>
    </div>
  );
}

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const navigate = useNavigate();
  const authuser = localStorage.getItem("AuthUser");
  return authuser && JSON.parse(authuser).userData?.is_new_password == 1 ? (
    <Element {...rest} />
  ) : (
    <Login />
  );
};

export default App;
