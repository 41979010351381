import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Divider, Row, message } from "antd";
import Button from "react-bootstrap/Button";
import LoadingOverlay from "./loader";
import axios from "../helper/api/api_instance";
import QRCode from "qrcode.react";
import downloadQrIcon from "../assets/images/icn_download_qr.png";

const QrDialog = (props) => {
    let qrCodeRef = React.createRef();
    const downloadQR = () => {
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "123456.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">My QR</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* { isLoading && <LoadingOverlay /> } */}

        <div className="d-flex align-items-center justify-content-center">
          <div
            style={{
              backgroundColor: "#0081C626",
              padding: "20px",
              borderRadius: "5px",
              borderColor: "1px solid #0081C626",
            }}
          >
            <QRCode
            id='qr-code'
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`https://app-staging.staytrufit.com/signup?code=${props?.code}`}
              ref={qrCodeRef} 
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>

        <div className="mt-30 t-center">
          <Button
            variant="primary"
            onClick={downloadQR}
            style={{ width: "30%", borderRadius: "21px", padding: "10px 10px" }}
          >
            <img src={downloadQrIcon} alt="qr-download" height={15} />
            <span className="mx-2">Download</span>
          </Button>{" "}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QrDialog;
