import React, { useState } from "react";
import { Dropdown, Table, ConfigProvider } from "antd";
const { Column} = Table;
const TableData = (props) => {
  const [data, setData] = useState();
  let { dataSource, columns, actionOption } = props;
  const items = actionOption.map((x, i) => {
    return {
      label: (
        <div
        >
          {x.name}
        </div>
      ),
      key: i,
    };
  });

  return (
    <div>
      <p>Table Component</p>


      <ConfigProvider
                      theme={{
                        components: {
                          Table: {
                            /* here is your component tokens */
                            headerBg: '#E1EDFB'
                          },
                        },
                      }}>
      <Table dataSource={dataSource} pagination={false}>
        {columns.map((x, i) => {
          return <Column title={x.title} dataIndex={x.dataIndex} key={x.key} />;
        })}
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Dropdown
              onClick={() => setData(record)}
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <span onClick={(e) => e.stopPropagation()} className="action-menu">
                {/* <img alt="" src={actionBtn} className="actions-col-btn" /> */}
                action
              </span>
            </Dropdown>
          )}
        />
      </Table>
    </ConfigProvider>


    </div>
  );
};

export default TableData;
